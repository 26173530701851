import * as FlightShopHelper from '@/core/utils/flightShopHelpers';
import { v4 as uuidv4 } from 'uuid';

export const transformNavigationMenuItem = (
  menuItem,
  { contextFlight = null, $vue } = {}
) => {
  if (!menuItem) throw new Error('Menu item cannot be null');
  if (!$vue) {
    // eslint-disable-next-line no-console
    console.warn('No reference to $vue; Defaulting to mocks');
    $vue = {
      $t: (a) => a,
      $te: (a) => false,
    };
  }

  if (!$vue?.$pathPrefix) {
    // eslint-disable-next-line no-console
    console.warn('$pathPrefix not implemented; Defaulting to mocks');
    $vue.$pathPrefix = (a) => a;
  }

  const transformers = {
    home: (menuItem) =>
      defaultTransformer(
        menuItem,
        {
          id: 'home',
          to: '/concierge',
          icon: 'mdi-home',
          enable: true,
          translationKey: 'home',
          external: false,
        },
        { $vue }
      ),
    experiences: (menuItem) =>
      defaultTransformer(
        menuItem,
        {
          id: 'experience',
          translationKey: 'experiences',
          to: '/discover',
          icon: require('@/assets/experiences_icon.svg'),
          enable: true,
          external: false,
        },
        { $vue }
      ),
    dining: (menuItem) =>
      defaultTransformer(
        menuItem,
        {
          id: 'dining',
          translationKey: 'dining',
          to: '/dining',
          icon: 'mdi-silverware-fork-knife',
          enable: true,
          external: false,
        },
        { $vue }
      ),
    transportation: (menuItem) =>
      defaultTransformer(
        menuItem,
        {
          id: 'transportation',
          translationKey: 'transportation',
          to: '/transportation',
          icon: 'mdi-car',
          enable: true,
          external: false,
        },
        { $vue }
      ),
    health_services: (menuItem) =>
      defaultTransformer(
        menuItem,
        {
          id: 'health_services',
          translationKey: 'health-services',
          to: '/health/ite_9kVhFVhhFVhhhhh9_',
          icon: require('@/assets/health_icon.svg'),
          enable: true,
          external: false,
        },
        { $vue }
      ),
    travel_info: (menuItem) =>
      defaultTransformer(
        menuItem,
        {
          id: 'travel_info',
          translationKey: 'travel-info',
          to: '/information',
          icon: 'mdi-cellphone-information',
          enable: true,
          external: false,
        },
        { $vue }
      ),
    shop: (menuItem) => shopTransformer(menuItem, { contextFlight, $vue }),
    virtual_goods: (menuItem) =>
      vgTransformer(menuItem, { contextFlight, $vue }),
    priority: (menuItem) => vgTransformer(menuItem, { contextFlight, $vue }),
    other: (menuItem) => externalLinkTransformer(menuItem, { $vue }),
  };

  if (transformers[menuItem.nameKey]) {
    return transformers[menuItem.nameKey](menuItem, { contextFlight, $vue });
  } else {
    return transformers.other(menuItem, { contextFlight, $vue });
  }
};

const validateAndTruncateSlug = (base, menuItem) => {
  let link = '' + base;
  const slug = menuItem.to;
  if (slug) {
    if (slug.startsWith('/')) {
      link += slug;
    } else if (slug.startsWith('http')) {
      Sentry.setExtras(menuItem);
      Sentry.captureMessage(
        `Cannot use full link with "to" property. Please use href with nameKey "other". `,
        Sentry.Warning
      );
    } else {
      link = link + '/' + slug;
    }
  }

  return link;
};

const vgTransformer = (menuItem, { $vue }) => {
  let toLink = validateAndTruncateSlug('/offerings', menuItem);
  const conciergeShopPathV4 = $vue.$store?.state.conciergeShopPathV4;
  return {
    id: 'virtual-goods-' + uuidv4(),
    link: {
      to: $vue.$pathPrefix(toLink, conciergeShopPathV4 ? '/v4' : '/v3'),
    },
    icon: menuItem.icon ?? require('@/assets/priority-boarding-pass.svg'),
    enable: true,
    external: true,
    text: $vue.$te(`menu.${menuItem.name}`)
      ? $vue.$t(`menu.${menuItem.name}`)
      : menuItem.name,
  };
};

const shopTransformer = (menuItem, { contextFlight, $vue }) => {
  const isShopV4 = $vue.$store?.getters.isShopV4;
  const conciergeShopPathV4 = $vue.$store?.state.conciergeShopPathV4;

  const disableTooltipMessage = (flight) => {
    return !flight
      ? $vue.$t('menu.shop-no-flight-tooltip')
      : !FlightShopHelper.isCurrentFlightAvailableForPreorder(flight)
      ? $vue.$t('menu.shop-catalog-unavailable-tooltip')
      : '';
  };

  const shopTranslationKey = menuItem.name ? menuItem.name : 'shop';

  const isEnable = isShopV4
    ? isShopV4
    : contextFlight &&
      contextFlight?.storeStatus !== 'CLOSED' &&
      FlightShopHelper.isCurrentFlightAvailableForPreorder(contextFlight);

  return defaultTransformer(
    menuItem,
    {
      id: 'shop',
      translationKey: shopTranslationKey,
      to: $vue.$pathPrefix(`/shop`, conciergeShopPathV4 ? '/v4' : '/v3'),
      icon: 'mdi-cart',
      enable: isEnable,
      external: true,
      disableTooltip: disableTooltipMessage(contextFlight),
    },
    { $vue }
  );
};

const externalLinkTransformer = (menuItem, { $vue }) => {
  return {
    id: 'other-' + uuidv4(),
    text: $vue.$te(`menu.${menuItem.name}`)
      ? $vue.$t(`menu.${menuItem.name}`)
      : menuItem.name,
    link: {
      href: menuItem.href,
    },
    icon: menuItem.icon || null,
    enable: menuItem.enable == null ? true : menuItem.enable,
    external: menuItem.external == null ? true : menuItem.external,
  };
};

const defaultTransformer = (
  menuItem,
  {
    id = 'other',
    translationKey,
    to,
    icon,
    enable = true,
    external = false,
    disableTooltip,
  },
  { $vue }
) => {
  let toLink = validateAndTruncateSlug(to, menuItem);
  const text = menuItem.name
    ? $vue.$te(`menu.${menuItem.name}`)
      ? $vue.$t(`menu.${menuItem.name}`)
      : menuItem.name
    : $vue.$t(`menu.${translationKey}`);
  return {
    id: id + '-' + uuidv4(),
    text: text,
    link: {
      to: toLink,
    },
    icon: icon || menuItem.icon,
    enable: enable,
    external: external,
    disableTooltip,
  };
};
