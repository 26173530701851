<template>
  <v-app>
    <glx-env-banner v-if="sandboxBanner" :text="sandboxBanner"></glx-env-banner>
    <easteregg
      v-if="enableThemes"
      @easter="goToTheme"
      :eggs="eggs"
      duration="5000"
    />

    <Shell :isTouchDeviceDetected="isTouchDeviceDetected" />

    <template v-if="!isTouchDeviceDetected">
      <CookieBanner />
    </template>
  </v-app>
</template>

<script>
import easteregg from 'vue-easteregg';
import CookieBanner from './modules/notifications/components/CookieBanner/CookieBanner.vue';
import Shell from './modules/shell/Shell.vue';
import { isWebkit } from '@/core/utils/device';
import UpdateMixins from '@/core/mixins/UpdateMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import LanguageMixins from '@/core/mixins/LanguageMixins';
import { waitForConfigs } from '@/store/modules/featureFlags/utils';
import HotJarMixins from '@/core/mixins/HotJarMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import axios from 'axios';

export default {
  name: 'App',
  components: { easteregg, CookieBanner, Shell },
  mixins: [
    UpdateMixins,
    FlightsMixins,
    LanguageMixins,
    HotJarMixins,
    GoogleTagManagerMixins,
  ],

  async created() {
    if (isWebkit) {
      this.$store.dispatch('enableEmbedded');
    }
    Promise.all([
      this.checkCleanFlightFF(),
      this.checkThemesFF(),
      this.loadDisplayTestDataValue(),
      this.loadShowFlightPreorderAvailability(),
      this.loadFlightSearchByCodeShareFlightNumber(),
      this.checkFlightRestrictionFF(),
      this.checkShopOnlyModeFF(),
      this.checkConciergeV3(),
      this.checkHotJarFF(),
      this.checkMaintenanceFF(),
      this.checkShopV4FF(),
      this.checkConciergeShopPathV4FF(),
      waitForConfigs(),
    ]).then(this.updateContextFlightsAndSelection);
    this.checkUserLocationByIp();

    if (this.$route.query?.mobileWrapp) {
      localStorage.setItem('mobileWrapp', 'true');
    }

    const isWrapped = localStorage.getItem('mobileWrapp');
    if (isWrapped) {
      this.$store.dispatch('isMobileWrapp', isWrapped === 'true');
    }
  },

  data: () => ({
    eggs: ['easter', ['ArrowUp', 'ArrowUp', 'ArrowUp', 'ArrowDown', 't']],
    enableThemes: false,
    flightRestrictionFF: false,
    flightsCleanupFF: false,
    hotJarFF: false,
    shopV4FF: false,
    flightPreorderAvailabilityFF: null,
    pollMaintenanceFF: false,
    checkMaintenance: 0,
    adBlockerDetected: false,
  }),

  mounted() {
    this.detectAdBlocker();
  },

  methods: {
    async updateContextFlightsAndSelection() {
      if (this.$route.name !== 'add-flight' && !this.queryFlightNumbers) {
        if (this.$store.state.shopOnlyMode) {
          await this.updateContextFlightsStoreStatuses();
        }
        this.selectNextContextFlightFlight();
      }
    },

    detectAdBlocker() {
      const testAdScript = document.createElement('script');
      testAdScript.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      testAdScript.onerror = () => {
        this.adBlockerDetected = true;
      };
      document.head.appendChild(testAdScript);
    },

    async checkCleanFlightFF() {
      this.flightsCleanupFF = await this.$ldclient.variation(
        'flights-cleanup',
        false,
        true
      );
      this.$ldclient.$on('change:flights-cleanup', (value) => {
        this.flightsCleanupFF = value;
      });
    },

    async checkThemesFF() {
      this.enableThemes = await this.$ldclient.variation(
        'themes-page',
        false,
        true
      );
      this.$ldclient.$on('change:themes-page', (value) => {
        this.enableThemes = value;
      });
    },

    async checkFlightRestrictionFF() {
      this.flightRestrictionFF = await this.$ldclient.variation(
        'flight-restriction',
        false,
        true
      );
      this.$ldclient.$on('change:flight-restriction', (value) => {
        this.flightRestrictionFF = value;
      });
    },

    async checkShopOnlyModeFF() {
      let response = await this.$ldclient.variation(
        'shop-only-mode',
        false,
        true
      );
      this.$store.dispatch('onShopOnlyMode', response);
      this.$ldclient.$on('change:shop-only-mode', (value) => {
        this.$store.dispatch('onShopOnlyMode', value);
      });
    },

    async checkConciergeV3() {
      let response = await this.$ldclient.variation(
        'concierge-ui-refresh-v3',
        false,
        true
      );
      this.$store.dispatch('checkConciergeV3', response);
      this.$ldclient.$on('change:concierge-ui-refresh-v3', (value) => {
        this.$store.dispatch('checkConciergeV3', value);
      });
    },

    async checkHotJarFF() {
      this.hotJarFF = await this.$ldclient.variation('hotjar', false, true);
      this.$ldclient.$on('change:hotjar', (value) => {
        this.hotJarFF = value;
      });
    },

    async checkShopV4FF() {
      let response = await this.$ldclient.variation('shop-v-4', false, true);
      this.$store.dispatch('isShopV4', response);

      this.$ldclient.$on('change:shop-v-4', (value) => {
        this.$store.dispatch('isShopV4', value);
      });
    },

    async checkConciergeShopPathV4FF() {
      const shopPathv4 = await this.$ldclient.variation(
        'concierge-shop-path-v-4',
        false,
        true
      );
      this.$store.dispatch('setConciergeShopPathV4', shopPathv4);
      this.$ldclient.$on('change:concierge-shop-path-v-4', (value) => {
        this.$store.dispatch('setConciergeShopPathV4', value);
      });
    },

    goToTheme() {
      this.$router.push({ name: 'theme' });
    },

    async loadDisplayTestDataValue() {
      let response = await this.$ldclient.variation(
        'display-test-data',
        false,
        true
      );
      this.$store.dispatch('onDisplayTestDataValueChange', response);
      this.$ldclient.$on('change:display-test-data', (value) => {
        this.$store.dispatch('onDisplayTestDataValueChange', value);
      });
    },

    async loadShowFlightPreorderAvailability() {
      let response = await this.$ldclient.variation(
        'show-flight-preorder-availability',
        false,
        true
      );
      this.flightPreorderAvailabilityFF = response;
      this.$store.dispatch('onShowFlightPreorderAvailability', response);
      this.$ldclient.$on(
        'change:show-flight-preorder-availability',
        (value) => {
          this.flightPreorderAvailabilityFF = value;
          this.$store.dispatch('onShowFlightPreorderAvailability', value);
        }
      );
    },

    async loadFlightSearchByCodeShareFlightNumber() {
      let response = await this.$ldclient.variation(
        'get-code-share-operator',
        false,
        true
      );
      this.$store.dispatch('onSearchFlightByCodeShareNumber', response);
      this.$ldclient.$on('change:get-code-share-operator', (value) => {
        this.$store.dispatch('onSearchFlightByCodeShareNumber', value);
      });
    },

    async checkMaintenanceFF() {
      this.pollMaintenanceFF = await this.$ldclient.variation(
        'poll-maintenance',
        true,
        true
      );
      this.$ldclient.$on('change:poll-maintenance', (value) => {
        this.pollMaintenanceFF = value;
      });
    },

    updateFavicon(configs) {
      if (Boolean(configs.favicons?.length)) {
        const head = document.head || document.getElementsByTagName('head')[0];
        configs.favicons.forEach((icon) => {
          const link = document.createElement('link');
          link.rel = icon.rel;
          link.type = icon.type;
          link.href = icon.href;
          link.sizes = icon.sizes;

          head.appendChild(link);
        });
      }
    },

    checkUserLocationByIp() {
      this.$store.dispatch('getUserLocation');
    },

    async checkForCodeShareSnackbar(flight) {
      const snackbar = flight?.isCodeShare && flight?.codeShares.length;
      const codeShareInfo = {
        codeShareAirlineName: this.airlinesCode[
          flight?.codeShares[0]?.carrierCode
        ],
        airlineName: this.airlinesCode[flight.flightNumber.replace(/\d+/g, '')],
      };
      await this.$store.dispatch('flights/setCodeShareAlert', snackbar);
      await this.$store.dispatch('flights/setCodeShareInfo', codeShareInfo);
    },
  },

  computed: {
    configs() {
      return this.$store.state.configs;
    },

    sandboxBanner() {
      if (this.configs) {
        return this.configs.sbox_mode;
      } else {
        return null;
      }
    },

    isTouchDeviceDetected() {
      return this.$store.state.isMobileWrapp;
    },
  },

  watch: {
    queryFlightNumbers: {
      immediate: true,
      async handler(val) {
        await this.loadShowFlightPreorderAvailability();
        if (!!val && this.flightPreorderAvailabilityFF !== null) {
          await this.addQueryFlightsToContext(
            this.queryFlightNumbers,
            this.queryFlightDates
          );
          const flight = this.selectedContextFlight;
          if (flight) {
            await this.checkForCodeShareSnackbar(flight);
          }
        }
      },
    },

    adBlockerDetected(val) {
      if (val) {
        this.pushAdBlockerDetection();
      }
    },

    configs: {
      immediate: true,
      handler(configs) {
        if (configs) {
          this.updateFavicon(configs);
          this.updateDefaultLanguage(configs.languages);
          if (this.flightsCleanupFF) {
            this.cleanContextFlights();
          }
        }
      },
    },

    flightsCleanupFF(val) {
      if (!!val) {
        if (!!this.configs) {
          this.cleanContextFlights();
        }
      }
    },

    ['$route.query.embedded']: {
      immediate: true,
      handler(embedded) {
        if (!!embedded && embedded === 'true') {
          this.$store.dispatch('enableEmbedded');
        }
      },
    },

    ['$route.query.travelerId']: {
      immediate: true,
      handler(travelerId) {
        if (travelerId) {
          this.$store.dispatch('setTravelerId', travelerId);
        }
      },
    },

    flightRestrictionFF(val) {
      if (!!this.flightRestrictionFF) {
        this.$store.dispatch('initFlightRestriction', { vm: this });
      }
    },

    hotJarFF(val) {
      if (!!val) {
        this.createHotJarInstance();
      }
    },

    pollMaintenanceFF(val) {
      if (val) {
        const checkMaintenanceStatus = () => {
          axios.get(`/_external/up`).catch((err) => {
            if (err.response.status === 503) {
              location.reload();
            }
          });
        };
        checkMaintenanceStatus();
        this.checkMaintenance = setInterval(checkMaintenanceStatus, 60000);
      } else {
        clearInterval(this.checkMaintenance);
        this.checkMaintenance = 0;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.checkMaintenance);
    this.checkMaintenance = 0;
  },
};
</script>
